* {
	padding: 0;
	margin: 0;
	font-family: 'Poppins', sans-serif;
}

ul {
	list-style-type: none;
	padding: 0;
}

body {
	overflow-x: hidden !important;
}

.link {
	text-decoration: none;
}

:root {
	--primary-color: #00293e;
	--secondary-color: #191139;
	--banner-color: #00324B;
	--heading: #1E96D3;
	--orange: #FFA500;
	--text: #7b7b7b;
	--heads: #003854;
}

.box {
	/* background-color: steelblue;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 400px;
	height: 200px;
	margin: 10px;
	border-radius: 10px;
	box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3); */
	transform: translateX(-400%);
	transition: transform 1s ease;
}

.box:nth-of-type(even) {
	transform: translateX(400%);

}

.box.showDiv {
	transform: translateX(0);
}

.sideNavbar {
	width: 300px;
	height: 100vh;
	position: fixed;
	top: 0;
	left: -400px;
	background-color: var(--secondary-color);
	color: white;
	transition: all .35s linear;
	z-index: 99;
}

.sideNavbar .header {
	font-size: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	padding: 0px 20px;
}

.header2 {
	display: none;
}

.accordion_container {
	/* width: 300px; */
	/* background-color: #efefef; */
	/* box-shadow: 8px 8px 7px #454545; */
	border-radius: 5px;
	overflow: hidden;
	/* border: 3px solid black; */
}

/* style the title button of the accordion menu */
.accordion_title {
	width: 100%;
	/* height: 50px; */
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	/* font-family: "Open Sans", sans-serif; */
	font-size: 16px;
	font-weight: 400;
	background-color: transparent;
	color: white;
}

.accordion_content {
	height: 0px;
	transition: height 0.3s ease-in;
}

.show_content {
	height: 150px;
	opacity: 1;
	visibility: visible;
	transition: height 0.3s ease-out;
}

.list_item_container {
	width: 100%;
	padding-left: 15px;
	display: flex;
	justify-content: left;
	align-items: center;
}

.accordion_content .list_item_container:first-of-type {
	border-top: 3px solid black;
}

.list_item_container p {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 14px;
}

.list_item_container:hover>p {
	color: #0072c9;
}

.header {
	background-color: var(--secondary-color);
	color: white;
}

.stcikyHeader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
}

.header .container .mainNav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}

.header .container .mainNav .nav_links {
	display: flex;
	align-items: end;
	height: 60px;
	padding-bottom: 2px;
}

.header2 .container .mainNav .nav_links {
	display: flex;
	align-items: center;
	height: 60px;
	padding-bottom: 2px;
}

.header .container .mainNav .nav_links ul {
	display: flex;
	align-items: center;

}

.header .container .mainNav .nav_links ul li {
	margin-right: 1.2rem;
	font-weight: 400;
	cursor: pointer;
	font-size: 16px;
}

.header .container .mainNav .nav_links ul li:last-child {
	margin-right: 0;
	font-weight: 600;
}

.header .container .mainNav .nav_links ul li:first-child {
	position: relative;
	transition: all .35s;
}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown {
	display: none;
	position: absolute;
	background-color: #fff;
	min-width: 160px;
	/* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
	padding: 12px 16px;
	z-index: 99;
}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown .serviceDropdownContent {
	/* background-color: white; */
	color: black;
	/* border-radius: 10px; */
	width: 200px;
}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown .serviceDropdownContent ul {
	display: flex;
	flex-direction: column;
	padding: 1rem .7rem;
	font-size: 14px;
	align-items: start;

}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown .serviceDropdownContent ul li {
	margin-bottom: 10px;
}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown .serviceDropdownContent ul li a {
	margin: 0;
	width: 100%;
	color: rgb(32, 32, 32);

}

.header .container .mainNav .nav_links ul li:first-child .serviceDropdown .serviceDropdownContent ul li:last-child {
	font-weight: normal;
}

.header .container .mainNav .nav_links ul li:first-child:hover .serviceDropdown {
	display: block;
	cursor: pointer;
}

/* banner section */

.banner {
	/* background-color: var(--banner-color); */
	/* background-color: linear-gradient(#110932, #171952, #452766, #252594, #484080, #4d2b98, #2b22b0, #8169cd); */
	background-color: #191139;
	color: white;
	padding-top: 3.5rem !important;
}

.designBannerPa {
	padding-top: 4.5rem !important;
}

.banner .bannerContent .contentContainer .bannerText {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.banner .bqCWu,
.banner .iWchow {
	color: white !important;
	background: transparent !important;
	box-shadow: none !important;
}

.banner .bannerContent .contentContainer .bannerText h1 {
	color: var(--heading);
	line-height: 60px;
	font-size: 40px;
	font-weight: 400;
}

.banner .bannerContent .contentContainer .bannerText p {
	line-height: 30px;
	font-size: 18px;
	font-weight: 100;
}

.banner .bannerContent .contentContainer .bannerImg {
	display: flex;
	justify-content: end;

}

/* limitation */
.limitation .container-fluid .row {
	display: flex;
	align-items: center;
}

.limitation .container-fluid .row .textSection .imge {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
}

.limitation .container-fluid .row .textSection {
	display: flex;
	align-items: center;
	position: relative;
}

.limitation .container-fluid .row .textSection h1:nth-child(1) {
	font-size: 75px;
	position: absolute;
	top: 45px;
	left: 15rem;
	color: var(--primary-color);
}

.limitation .container-fluid .row .textSection h1:nth-child(2) {
	font-size: 60px;
	position: absolute;
	bottom: 9.4rem;
	left: 19rem;
	color: var(--heading);
}

.limitation .container-fluid .row .textSection h1:nth-child(3) {
	font-size: 60px;
	position: absolute;
	bottom: 4.3rem;
	left: 16rem;
	color: var(--primary-color);
}

.limitation .container-fluid .row .textSection .imge .text {
	font-size: 32px;
	font-weight: 400;
	color: var(--heading);
	position: absolute;
	left: 167px;
}

.limitation .container-fluid .row .textSection .ro {
	display: flex;
	position: absolute;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	bottom: -37px;
	left: 14rem;
	width: 62%;
	text-align: center;
	color: var(--primary-color);
}

.limitation .container-fluid .row .textSect {
	position: relative;
}

.limitation .container-fluid .row .textSect .content p {
	font-weight: 300;
	position: absolute;
	top: 100px;
	line-height: 25px;
	padding: 0px 40px;
}

/* digital designs */
.development,
.digital {
	overflow-x: hidden !important;
}

.digital .container .row .col-md-6 h1 {
	color: var(--heads);
	margin: .6rem 0;
}

.digital .container .row .col-md-6 p {
	color: var(--text);
	font-size: 15px;
}

.digital .container .row .col-md-6 h4 {
	color: var(--orange);
	font-size: 17px;
	font-weight: 600;
}

.digital .container .row .col-md-6 ul li {
	color: var(--text);
	position: relative;
	left: 15px;
	margin-bottom: 3px;
	padding-top: 5px;
}

.digital .container .row .col-md-6 ul li::before {
	content: '';
	position: absolute;
	left: -15px;
	bottom: 8px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	border: 1px solid var(--orange);
}

.digital .container .row .col-md-6 ul li:last-child:before {
	content: '';
	position: absolute;
	left: -10px;
	bottom: 8px;
	height: 8px;
	width: 20px;
	border-radius: 50%;
	border: 1px solid transparent;
}

.digital .container .row .col-md-6 ul li:last-child .link {
	text-decoration: none;

}

/* development designs */
.development_quote {
	background: url("./Images/shutterstock_76996753\ 1.png") no-repeat;
	position: relative;
	height: 580px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	background-size: cover;
}

.development_quote2 {
	background-image: url("./Images/Mask\ group.png");
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute !important;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 580px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.development_quote h1 {
	font-weight: 800;
	font-size: 70px;
	color: white;
}

.development_quote h1::before,
.development_quote h1::after {
	content: "\201C";
	font-size: 70px;
	color: white;
}

.development_quote h1::after {
	content: "\201D";
}

.development_quote2 h1 {
	font-weight: 800;
	font-size: 50px;
	color: #003854;
}

.development_quote2 h1::before,
.development_quote2 h1::after {
	content: "\201C";
	font-size: 70px;
	color: #fff;
}



/* deigital_marketing designs */
.marketing {
	overflow-x: hidden !important;
}

.marketing .container .row .col-md-6 h1 {
	color: var(--heads);
	margin: .6rem 0;
}

.marketing .container .row .col-md-6 p {
	color: var(--text);
	font-size: 15px;
}

.marketing .container .row .col-md-6 h4 {
	color: var(--orange);
	font-size: 17px;
	font-weight: 600;
}

.marketing .container .row .col-md-6 ul li {
	color: var(--text);
	position: relative;
	left: 15px;
	margin-bottom: 3px;
	padding-top: 5px;
}

.marketing .container .row .col-md-6 ul li::before {
	content: '';
	position: absolute;
	left: -15px;
	bottom: 8px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	border: 1px solid var(--orange);
}

.marketing .container .row .col-md-6 ul li:last-child:before {
	content: '';
	position: absolute;
	left: -10px;
	bottom: 8px;
	height: 8px;
	width: 20px;
	border-radius: 50%;
	border: 1px solid transparent;
}

.marketing .container .row .col-md-6 ul li:last-child .link {
	text-decoration: none;
}

/* FAQ */
.faq_container {
	background-color: var(--secondary-color);
	/* background: linear-gradient(#110932, #171952, #452766, #252594, #484080, #4d2b98, #2b22b0, #8169cd); */
	color: white;
}

.faq_container .row:nth-child(1) .frequently {
	position: relative;
	font-weight: 500;
	width: max-content;
	margin: auto;
}

.faq_container .row:nth-child(1) .frequently::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: white;
	bottom: -10px;
	left: 0;
}

.faq_container .row:nth-child(2) .accordion {
	background-color: transparent !important;
	color: white !important;
}

.faq_container .row:nth-child(2) .accordion-item {
	border: none !important;
	border-radius: 0 !important;
	background-color: transparent;
	color: white !important;
	position: relative;
}

.faq_container .row:nth-child(2) .accordion-item .accordion-body .accordion-header .accordion-button {
	font-size: 30px;
	font-weight: 600;
}

.faq_container .row:nth-child(2) .accordion-item .accordion-body p {
	font-size: 13px;
}

.faq_container .row:nth-child(2) .accordion-button:not(.collapse) {
	background-color: transparent !important;
	box-shadow: none !important;
}

.faq_container .row:nth-child(2) .accordion-button {
	color: white !important;
	/* border-bottom: 1px solid !important; */
}

.faq_container .row:nth-child(2) .accordion-button::after {
    background-image: url('../src/Images/down-arrow.png');
    background-size: 30px;
    transition: transform 0.3s ease-in-out;
}

.faq_container .row:nth-child(2) .accordion-button.rotate::after {
    transform: rotate(0deg);
}
.faq_container .row:nth-child(2) .accordion-button:focus {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.innovate_container .row .inovate_content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	height: 560px;
}

.innovate_container .row .inovate_content h1 {
	font-size: 5.4rem;
	font-weight: 900;
	text-align: center;
	color: #003854;
}

.innovate_container .row .inovate_content h1 .stroke {
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #1E96D3;
	-webkit-text-fill-color: transparent;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
}

.innovate_container .row .inovate_content p {
	color: #10445F;
	font-size: 1.3rem;
}

.innovate_container .row .inovate_content button {
	position: relative;
	background-color: white;
	padding: 0px 20px;
	font-size: 14px;
	border-radius: 4px;
	cursor: pointer;
	color: #2C9DD6 !important;
	border: none;
}

.innovate_container .row .inovate_content button:hover {
	color: #2C9DD6;
}

.innovate_container .row .inovate_content button::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	z-index: -1;
	border-radius: 4px;
	background: linear-gradient(to right, #FF01D6, #6B63D4, #1E96D3, #616AD4);
}

/* footer */
.footer {
	background: var(--secondary-color);
	/* background: linear-gradient(#110932, #171952, #452766, #252594, #484080, #4d2b98, #2b22b0, #8169cd); */
	background-size: cover;
	color: white;
	position: relative;
}

.backtotop {
	position: absolute;
	top: -30px;
	left: 48%;
}

.footer .container .row .col-lg-3 .heading {
	font-size: 24px;
	font-weight: 100;
	margin-bottom: 20px;
}

.footer .container .row .col-lg-3 .link {
	display: flex;
	align-items: center;
}

.icon-circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	background-color: #fff;
	border-radius: 50%;
	margin-right: 10px;
}

.icon-circle svg {
	color: #2173BA;
	font-size: 20px;
}

.footer .container .row .col-lg-3 ul li {
	margin-bottom: 13px;
	color: #CFDADF;
	font-weight: normal;
	font-size: 13px;
}

.phone_icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	transform: rotate(90deg);
}

.phone_icon svg {
	color: #ffffff;
	font-size: 20px;
}

.envelop_icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.envelop_icon svg {
	color: #ffffff;
	font-size: 20px;
}

.newsletter h5 {
	font-size: 16px;
}

.newsletter .newsForm {
	border: 1px solid lightgrey;
	border-radius: 6px;
	background-color: #fff;
}

.newsletter button {
	border: none;
	background-color: #1E96D3;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	color: white;
	font-size: 14px;
	padding: 0px 10px;
}

.newsletter form {
	background-color: #fff;
	border-radius: 6px;
}

.newsletter form input:focus {
	border: none;
	box-shadow: none;
}

.whiteline {
	border: none;
	border-top: 2px solid rgb(255, 255, 255);
	/* Adjust the thickness as needed */
	margin: 20px 0;
	/* Adjust the margin as needed */
}

.newsletter form input {
	border: none;
	background-color: transparent;
}

.newsletter form input::placeholder {
	color: lightgrey;
}

/* are you ?? */

.areyou {
	margin: 50px 0px;
}

.areyou .container .row h1 {
	display: flex;
	flex-wrap: wrap;
	height: 48px;
	overflow: hidden;
}

.areyou .container .row h1 .simpleDive {
	color: #003854;
	margin-right: 10px;
	font-weight: 500;
}

.areyou .container .row h1 .animateDive {
	animation: moveUpAnim 4s infinite ease;
}

@keyframes moveUpAnim {
	0% {
		margin-top: 0px;
	}

	25% {
		margin-top: -48px;
	}

	50% {
		margin-top: -96px;
	}

	75% {
		margin-top: -48px;
	}

	100% {
		margin-top: 0px;
	}
}

.areyou .container .row h1 .head1,
.areyou .container .row h1 .head2,
.areyou .container .row h1 .head3 {
	color: #1E96D3;
	font-weight: 800;
	/* width: 80%; */
	/* border: 1px solid; */
	margin: 0;
}

.areyou .container .row p {
	color: #003854;
	font-weight: 500;
	margin-bottom: 2rem;
}

.areyou .container .row .col-lg-5 .services .serves {
	display: flex;
	align-items: baseline;
	/* border: 1px solid; */
	padding: 1rem;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0px 0px 4px 2px lightgray;
	margin-bottom: 2.6rem;
	/* width: 90%;   */
}

.areyou .container .row .col-lg-5 .services .serves .icon {
	margin-right: 20px;
}

.areyou .container .row .col-lg-5 .services .serves .text h3 {
	font-size: 16px;
	color: #003854;
	font-weight: 500;
}

.areyou .container .row .col-lg-7 .image1 {
	height: 350px;
	width: 290px;
}

.areyou .container .row .col-lg-7 .image1 img {
	width: 100%;
	height: 100%;
}

.areyou .container .row .col-lg-7 .image2 {
	height: 350px;
	width: 290px;
	position: relative;
}

.areyou .container .row .col-lg-7 .image2 img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 52%;
	left: -28px;
}

.areyou .container .row .col-lg-7 .image3 {
	height: 350px;
	width: 290px;
	position: relative;
}

.areyou .container .row .col-lg-7 .image3 img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 25%;
	right: 9%;
}

/* client Satify carousel */

.client_satisfy {
	height: 600px;
	margin-bottom: 80px;
}

.client_satisfy .row {
	height: 500px;
}

.client_satisfy .row .col-lg-7 .testimonials .imgConta .conta1 img {
	height: 130px;
	border-radius: 10px;
}

.client_satisfy .smallImg img {
	height: 90px;
	border-radius: 10px;
}

/* SERVICES DESIGN PAGE */
.design .bannerContent .container .contentContainer .bannerText h3 {
	color: #1E96D3;
	position: relative;
	width: max-content;
	font-size: 20px;
}

.design .bannerContent .container .contentContainer .bannerText h3::before {
	position: absolute;
	width: 100%;
	height: 2px;
	content: '';
	background-color: var(--heading);
	bottom: -5px;
}

.design .bannerContent .container .contentContainer .bannerText h1 {
	color: white;
	font-weight: 700;
}

.design .bannerContent .container .contentContainer .bannerText p {
	color: white;
	font-size: 16px;

}

.design .bqCWu {
	display: none;
}

/* experties */
.experties {
	background-color: #191139;
	/* background: linear-gradient(#110932, #171952, #452766, #252594, #484080, #4d2b98, #2b22b0, #8169cd), url("./Images/Group 625907.png") no-repeat; */
	color: white;
}

.experties .row .col-md-12 h1 {
	position: relative;
	width: max-content;
	margin: auto;

}

.experties .row .col-md-12 h1::before {
	position: absolute;
	width: 100%;
	height: 2px;
	content: '';
	background-color: var(--heading);
	bottom: -5px;
}

/* growth section */
.growth {
	height: 560px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.growth .row .col-md-12 h1 {
	position: relative;
	margin: auto;
	color: #1E96D3;
	width: fit-content;
}

.growth .row .col-md-12 h1::after {
	content: '';
	width: 100%;
	height: 3px;
	background-color: #1E96D3;
	position: absolute;
	left: 0;
	bottom: -5px;
}

.growth .row .col-md-12 p {
	font-size: 18px;
	color: var(--text);
}

.growth .row .col-md-3 .contents {
	display: flex;
	align-items: center;
	text-align: start;
}

.growth .row .col-md-3 .contents .text h3 {
	font-size: 20px;
	color: #3E494E;
}

.growth .row .col-12 h3 {
	font-size: 20px;
	color: #3E494E;
	line-height: 30px;
	margin: 20px 0;
}

.growth .row .col-md-3 .contents .text h6 {
	font-size: 13px;
	color: var(--orange);
}

.growth .row .col-md-3 .contents .arrowIcon .icon-circle {
	height: 65px;
	width: 65px;
	background-color: #D9D9D9;
}

.growth .row .col-md-3 .contents .arrowIcon .icon-circle .icon {
	color: #494949;
	font-size: 35px;
}

.growth button {
	position: relative;
	background-color: white;
	padding: 0px 20px;
	font-size: 14px;
	border-radius: 4px;
	cursor: pointer;
	color: #2C9DD6 !important;
	border: none;
}

.growth button:hover {
	color: #2C9DD6;
}

.growth button::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	z-index: -1;
	border-radius: 4px;
	background: linear-gradient(to right, #FF01D6, #6B63D4, #1E96D3, #616AD4);
}

.Digital_marketing_feature .row .col-lg-12 h1 {
	position: relative;
	width: max-content;
	margin: auto;
	color: var(--heading);
}

.Digital_marketing_feature .row .col-lg-12 h1::before {
	position: absolute;
	width: 100%;
	height: 2px;
	content: '';
	background-color: var(--heading);
	bottom: -5px;
}

.Digital_marketing_feature .row .col-lg-12 p {
	font-size: 18px;
	color: var(--text);
}

.Digital_marketing_feature .row .col-lg-3 .contents {
	display: flex;
	align-items: center;
	text-align: start;
}

.Digital_marketing_feature .row .col-lg-3 .contents .text h3 {
	font-size: 20px;
	color: #3E494E;
}

.Digital_marketing_feature .row .col-12 h3 {
	font-size: 20px;
	color: #3E494E;
	line-height: 30px;
	margin: 20px 0;
}

.Digital_marketing_feature .row .col-lg-3 .contents .text h6 {
	font-size: 13px;
	color: var(--orange);
}

.Digital_marketing_feature .row .col-lg-3 .contents .arrowIcon .icon-circle {
	height: 65px;
	width: 65px;
	background-color: #D9D9D9;
}

.Digital_marketing_feature .row .col-lg-3 .contents .arrowIcon .icon-circle .icon {
	color: #494949;
	font-size: 35px;
}

.Digital_marketing_feature button {
	position: relative;
	background-color: white;
	padding: 0px 20px;
	font-size: 14px;
	border-radius: 4px;
	cursor: pointer;
	color: #2C9DD6 !important;
	border: none;
}

.Digital_marketing_feature button:hover {
	color: #2C9DD6;
}

.Digital_marketing_feature button::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	z-index: -1;
	border-radius: 4px;
	background: linear-gradient(to right, #FF01D6, #6B63D4, #1E96D3, #616AD4);
}

/* degital cards */

.degital_cards .container .row .text h1 {
	color: var(--heading);
	font-size: 4rem;
}

.degital_cards .container .bg-col {
	background-image: url("./Images/Comp\ 1_3\ 2.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.degital_cards .container .row .text p {
	color: var(--text);
	font-size: 1.2rem;
}

.degital_cards .container .row .card {
	border: none !important;
	box-shadow: 0px 0px 10px 2px #3030301a !important;
	padding: 15px 5px;
	border-radius: 0;
}

.degital_cards .container .row .card h5 {
	line-height: 30px;
	color: #494949;
	font-size: 1.7rem;
}

.degital_cards .container .row .card p {
	color: var(--text);
	font-size: 18px;
}

.degital_cards .container .row .cont .card {
	position: relative;
	top: 20%;
}

/* degital cards */
.cardTextContainer {
	background-image: url("./Images/Comp\ 1_3\ 2.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.development_cards .container .row .text h1 {
	color: var(--heading);
	font-size: 4rem;
}

.development_cards .container .row .text p {
	color: var(--text);
	font-size: 1.6rem;
}

.development_cards .container .row .card {
	border: none !important;
	box-shadow: 0px 0px 4px 2px #3030301a !important;
	padding: 15px 5px;
	border-radius: 0;
}

.degital_cards .container .row .card h5 {
	line-height: 30px;
	color: #494949;
	font-size: 24px;
}

.development_cards .container .row .card p {
	color: var(--text);
	font-size: 16px;
}

.development_cards .container .row .cont .card {
	position: relative;
	top: 20%;
}

/* development approach */
.approach {
	background-color: #191139;
	/* background: linear-gradient(#110932, #171952, #452766, #252594, #484080, #4d2b98, #2b22b0, #8169cd); */
}

.approach .underlin {
	position: relative;
}

.approach .underlin {
	color: var(--primary-color);
	position: relative;
	width: max-content;
	font-size: 30px;
	margin: auto;
}

.approach .underlin::before {
	position: absolute;
	width: 100%;
	height: 2px;
	content: '';
	background-color: var(--heading);
	bottom: -5px;
}

.approach .svgContent {
	position: relative;
}

.approach .svgContent .scgImg,
.approach .svgContent .scgImg1,
.approach .svgContent .scgImg2 {
	position: absolute;
	top: -10px;
	width: 100px;
	height: 100px;
	object-fit: contain;
}

.approach ul li {
	color: var(--text);
	position: relative;
	left: 15px;
	margin-bottom: 3px;
	padding-top: 5px;
}

.approach ul li::before {
	content: '';
	position: absolute;
	bottom: 8px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	left: -15px;
	border: 1px solid var(--orange);
}

/* development_tool */
.development_tool {
	background: #02344C url(./Images/Group\ 625907.png);
	color: white;
	padding: 4rem 0;
	display: flex;
	align-items: center;
}

.development_tool .container .row .col-md-8 .tools {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.development_tool .container .row .col-md-8 .tools .tool {
	text-align: center;
}

.development_tool .container .row .col-md-8 .tools .tool .toolImg {
	background-color: #142a35c2;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	margin-bottom: 10px;
	padding: 10px;
	height: 50px;
	width: 50px;
}

.development_tool .container .row .col-md-8 .tools .tool .toolImg img {
	width: 100%;
}


.development_tool .container .row .col-md-8 .tools .tool h6 {
	color: #fff;
	border-radius: 10px;
	font-size: 9px;
}

/* development_quote */
/* .development_quote {
	background: url("./Images/shutterstock_76996753\ 1.png") no-repeat;
	opacity: 0.9;
}

.development_quote {
	background-size: cover;
	background-repeat: no-repeat;
}

.development_quote h1 {
	font-weight: 900;
	font-size: 40px;
	color: white;
}

.development_quote {
	position: relative;
	padding: 8rem;

}

.development_quote h1::before,
.development_quote h1::after {
	content: "\201C";
	font-size: 70px;
	color: white;
}

.development_quote h1::after {
	content: "\201D";
} */


.development_experties {
	background: #033B53;

}

.development_experties h1 {
	font-size: 40px;
	-webkit-text-stroke-color: white;
	-webkit-text-stroke-width: 1px;
	color: #033B53;
	font-weight: 800;
	font-size: 65px;
	line-height: 80px;
	font-family: Arial, Helvetica, sans-serif;
}

.development_experties h2 {
	color: white;
}

.development_experties .bgImg {
	background: url('./Images/Comp\ 1_3\ 3.png') no-repeat;
	background-size: contain;
	display: flex;
	justify-content: center;
	align-items: center;
}

.experties_img {
	background-image: url("./Images/Frame\ 626001.png");
	background-repeat: no-repeat;
	background-size: cover;
	height: 77%;
	width: 100%;
	padding: 50px;

}

.experties_img h3 {
	color: white;
}

.contact_form {
	background-color: #F8F8F8;
}

.form-check-input {
	box-shadow: none !important;
}

.form-check-input:checked[type=radio] {
	--bs-form-check-bg-image: url("./Images/check.png") !important;
}

.contact_form p {
	color: #727272;
	font-weight: 600;
}

.contact_info {
	background-color: #00293e;
	padding: 30px;
	border-radius: 5px;
}

.contact_container {
	max-width: 1250px;
	margin: auto;
}

.contact_container_form {
	padding-right: 7rem;
}

.contact_info iframe {
	border-radius: 10px;
}

.phone {
	display: flex;
	color: #fff;
	align-items: baseline;
	gap: 10px;
}

.mail {
	display: flex;
	color: #fff;
	gap: 10px;
	align-items: baseline;
}

.address {
	display: flex;
	color: #fff;
	gap: 10px;
	align-items: baseline;
}

.contact_info .icons {
	font-size: 22px;
}

.contact_form .form-group input {
	border: none;
	border-bottom: 1px solid grey;
	border-radius: 0px;
	width: 100%;
}

.contact_form .form-group label {
	font-weight: 600;
}

.contact_form .form-group input::placeholder {
	font-size: 14px;
	color: lightgrey;
}

.contact_form .form-group input::-ms-value {
	font-size: 14px;
	color: lightgrey;
}

.form-button {
	text-align: right;
	/* padding: 8% 20%; */
}

.form-button button {
	width: 240px;
	height: 54px;
}

.contact_form .form-group input:focus {
	box-shadow: none;
	border-bottom: 1px solid gray;
}

.form-button button {
	background-color: #1E96D3;
	color: white;
	padding: 8px 16px;
	border: none;
	border-radius: 10px;
}

.subject {
	font-weight: 600;
	font-size: 16px;
}

.digital_marketing_cards .container .row .col-md-6 .text {
	line-height: 40px;
}

.digital_marketing_cards .container .row .col-md-6 .text h1 {
	font-size: 40px;
	color: black;
}

.digital_marketing_cards .container .row .col-md-6 .text p {
	font-size: 20px;
	line-height: 30px;
	color: var(--text);
}

.digital_marketing_cards .listCrds {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
	border-radius: none !important;
	padding: 15px;
}

.digital_marketing_cards .listCrds h4 {
	font-size: 18px;
	color: rgba(44, 44, 44, 0.705);
}

.digital_marketing_cards .downcrds {
	position: relative;
	top: 90px;
}

.digital_marketing_cards .downcrds2 {
	position: relative;
	top: 200px;
}

.digital_marketing_cards .card {
	border-radius: none !important;
}

.digital_marketing_cards .listCrds h5 {
	font-size: 18px;
}

.digital_marketing_cards .listCrds ul li {
	color: var(--text);
	position: relative;
	left: 15px;
	margin-bottom: -3px;
	/* padding-top: 5px; */
	font-size: 15px;
	font-weight: 300;
}

.digital_marketing_cards .listCrds ul li::before {
	content: '';
	position: absolute;
	left: -12px;
	bottom: 8px;
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background-color: var(--orange);
}

/* case study */
.case_study {
	background: var(--secondary-color) url('../src/Images/Frame\ 625926.png') no-repeat right center;
	background-size: contain;
}

.case_study .casecainter {
	position: relative;
	width: 397px;
	height: 250px;
	margin-left: 1px;
	margin-top: -7px;
}

/* .case_study .bgImg {
  background:url('../src/Images/wow3.jpeg'),rgba(0,0,0,0.9);
  background-size:cover;
  background-position: left;
} */
.case_study .container .row .col-md-6 {
	display: flex;
	align-items: center;
}

.case_study .container .row .col-md-6 .scrollAnimation {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.case_study .container .row .case_content p {
	font-size: 17px;
}

.case_study .container .row .case_content h2 {
	/* font-size: 35px; */
	font-weight: 700;
	color: white;
}

.case_study .container .row .case_content h1.h {
	/* font-size: 3.6rem; */
	font-weight: 800;
	color: #ffa500;
	text-shadow: 0px 2px 5px #ffa60088;
}

.case_study .container .row .ravenue .ravenue-1 {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 40px;
}

.case_study .container .row .ravenue .ravenue-1 p {
	font-size: 17px;
	color: #EFF2F3;
}

.case_study .container .row .ravenue .ravenue-1 .icon {
	transform: rotate(45deg);
	color: #6FDA44;
}

.case_study .container .row .ravenue .ravenue-1 h1 {
	color: white;
	font-weight: 800;
}

.case_study .container .row .ravenue .ravenue-1 h1 span:last-child {
	margin-left: 20px;
}

.digital_marketing_process {
	background-color: var(--secondary-color);
	color: white;
}

.digital_marketing_process .container .top h3 {
	text-align: center;
	font-size: 35px;
	color: #1E96D3;
	position: relative;
	width: max-content;
	margin: auto;
	margin-bottom: 30px;
}

.digital_marketing_process .container .top h3::before {
	position: absolute;
	width: 100%;
	height: 2px;
	content: '';
	background-color: white;
	bottom: -5px;
}

.digital_marketing_process .container .top p {
	text-align: center;
	font-size: 20px;
}

.digital_marketing_process .container .text1 {
	position: relative;
	padding: 25px;
}

.digital_marketing_process .container .text1 h1 {
	color: #327395;
	font-weight: 900;
	font-size: 90px;
}

.digital_marketing_process .container .text1 .overlay {
	position: absolute;
	top: 78px;
	left: 48px;
	background: none !important;
}

.digital_marketing_process .container .text1 .overlay h3 {
	font-size: 1.4rem;
	color: white;
}

.digital_marketing_process .container .picture img {
	width: 80px;
	margin-bottom: 35px;
}

.digital_marketing_process .container .lines {
	font-size: 16px;
	font-weight: 300;
}

.digital_marketing_speak .container .speak-heading h1 {
	font-size: 50px;
	line-height: 70px;
	color: #003854;
	font-weight: 600;
	text-transform: uppercase;
}

.digital_marketing_speak .container .speak-text p {
	color: var(--text);
	font-size: 22px;
	line-height: 40px;
}

.digital_marketing_speak .container .img {
	height: 100%;
	width: 100%;
}

.digital_marketing_speak .container .speak-text button {
	position: relative;
	background-color: white;
	padding: 0px 20px;
	font-size: 14px;
	border-radius: 4px;
	cursor: pointer;
	color: #2C9DD6 !important;
	border: none;
}

.digital_marketing_speak .container .speak-text button:hover {
	color: #2C9DD6;
}

.digital_marketing_speak .container .speak-text button::before {
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	z-index: -1;
	border-radius: 4px;
	background: linear-gradient(to right, #FF01D6, #6B63D4, #1E96D3, #616AD4);
}

.about_us_banner .container .banner-text h1 {
	font-size: 40px;
}

.about_us_banner .container .banner-text p {
	font-size: 20px;
}

.about_us_banner .container .about_img img {
	border-radius: 20px;
}

.about_question .container .question h1 {
	color: #242331;
	font-weight: bold;
	font-size: 50px;
}

.about_question .container .answer p {
	color: var(--text);
	font-size: 20px;
}

.about_counter .container {
	background-color: #f9f9f9;
	padding: 7% 0;
	border-radius: 10px;
}

.about_counter .container .counter span {
	color: #FFA500 !important;
}

.about_counter .container .counter h1 {
	font-size: 70px;
	font-weight: 700;
}

.about_counter .container .counter h6 {
	font-size: 22px;
}

/* about_us_mission */

.about_us_mission .container .row .col-md-6 .content h3 {
	color: var(--primary-color);
	font-size: 36px;
}

.about_us_mission .container .row .col-md-6 .content p {
	color: var(--text);
	font-size: 20px;
}

.about_us_mission .container .row .col-md-6 .image img {
	width: 100%;
	/* height: 300px; */
	border-radius: 20px;
}

.about_us_value .container .value-heading h6 {
	color: var(--heading);
}

.about_us_value .container .value-heading h1 {
	color: var(--banner-color);
	font-weight: 600;
}

.value-cards {
	background-color: #f9f9f9;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.about_us_value .container .value-cards .col-md-3 {
	padding: 30px;
}

.about_us_value .container .value-cards .col-md-3 img {
	height: 140px;
	width: 95px;
	border-radius: 20px;
}

.about_us_value .container .value-cards .heading {
	padding: 35px 0;
}

.about_us_value .container .value-cards .heading p {
	color: var(--text);
	font-size: 18px;
}

.about_us_value .container .value-cards .img_container {
	height: 140px;
	width: 105px;
	border-radius: 20px;
	background-color: #003854;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about_us_value .container .value-cards .img_container img {
	height: 50px;
	width: 40px;
	border-radius: 0;
}


.testimonial-section1 {
	background: var(--secondary-color) url('../src/Images/Frame\ 625926.png') no-repeat right center !important;
	/* background: red; */
	/* background-color: #191139; */
	background-size: contain;
	color: white;
}

.testi-img .img-box {
	max-width: 360px;
	margin: auto;
	text-align: center;
	position: relative;
}

.testi-img .circle {
	height: 360px;
	width: 360px;
	background-color: #fb9c9a;
	position: absolute;
	border-radius: 50%;
	left: 0;
	bottom: 0;
	transition: all 0.5s ease;
}

.testi-img .img-box-inner {
	position: relative;
	border-radius: 0 0 180px 180px;
	overflow: hidden;
}

.testi-img .img-box-inner img {
	max-width: 310px;
}

/* .testimonial-section .carousel-inner {
	margin-bottom: 20px;
} */

.testimonial-section .carousel-control-prev {
	margin-right: 10px;
}

.testimonial-section .carousel-control-next,
.testimonial-section .carousel-control-prev {
	position: relative;
	height: 40px;
	width: 40px;
	background-color: transparent;
	display: inline-flex;
	border-radius: 50%;
}

.slider .carousel-control-next,
.slider .carousel-control-prev {
	position: relative;
	height: 40px;
	width: 40px;
	display: inline-flex;
	border: 1px solid white;
	border-radius: 50%;

}

.slider .carousel-control-prev img,
.slider .carousel-control-next img {
	width: 19px;
}

.testi-item h3,
.testi-item p {
	font-size: 18px;
}

.testi-item h3 {
	text-transform: capitalize;
}

.animateImg {
	transform: translateY(-300px);
	/* animation: animateImg 2s infinite; */
}

/* Your CSS file */
.truncate-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
}


@keyframes animateImg {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(-300px);
	}

	100% {
		transform: translateY(0px);
	}
}

/* testimonial-section2 */
.testimonial-section {
	background-color: #eff4f6;
	background-image: none;
}

.testimonial-section .carousel .headding {
	font-size: 40px;
	font-weight: 600;
	color: var(--primary-color) !important;
}

.testimonial-section .carousel .slogan {
	font-size: 22px;
	font-weight: 500;
	color: #003854 !important;
}

.testimonial-section .bottom {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.testimonial-section .bottom .userdetail {
	display: flex;
	align-items: center;
	gap: 10px;
}

.testimonial-section .bottom .userdetail .img1 {
	border-radius: 10px;
}

.testimonial-section .bottom .userdetail .text h5 {
	font-size: 18px;
	font-weight: 500;
	color: #003854;
}

.testimonial-section .bottom .userdetail .text h6 {
	color: var(--text);
	font-weight: 400;
	font-size: 16px;
}

/* blog */
.blog .container .row .col-lg-8 .card .content .bottomText {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: var(--text);
	font-size: 15px;
}

.blog .container .row .col-lg-8 .card .col-md-7 {
	padding-right: 20px;
}

.blog .container .row .col-lg-8 .card {
	border-radius: 20px;
}

.blog .container .row .col-md-6 .curveCard {
	border-radius: 20px !important;
	/* width: 23rem; */
}

.blog .container .row .col-md-6 .curveCard .card-title {
	font-size: 20px;
}

.blog .container .row .col-md-6 .curveCard .bottomText {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: var(--text);
}

.blog .container .featured-blogs {
	padding: 0 20px;
}

.blog .container .featured {
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 15px;
}

.blog .container .row .col-md-3 .blog-img img {
	width: 85px;
	height: 89px;
	border-radius: 15px;
}

.blog .container .row .col-md-9 .blog-txt .bottomText {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: var(--text);
}

.blog .container .row .col-md-9 .blog-txt .bottomText p {
	font-size: 14px;
}

.videoSide {
	position: relative;
}

.featured-video {
	position: relative;
	height: 250px;
	border-radius: 20px;
	overflow: hidden;
	/* Ensure the video doesn't overflow the container */
}

.featured-video video {
	width: 100%;
	/* Make the video take the full width of the container */
	height: 100%;
	/* Make the video take the full height of the container */
	object-fit: cover;
	/* Maintain aspect ratio and cover the container */
}

.playBtnContainer {
	width: fit-content;
	border-radius: 50%;
	padding: 10px;
	padding-left: 11px;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.playBtn {
	font-size: 30px;
}

.grop11container {
	position: relative;
}

/* .grop11container .img1 {
	position: absolute;
	left: 0;
} */

.taabLayer {
	border-radius: 10px;
	z-index: 1;
	overflow: hidden;
	position: relative;
	/* height: 220px !important; */
	width: 178px !important;
	top: 41px !important;
	left: -55px !important;
}

/* blog detail */

.blog-details .details-img img {
	width: 100%;
	height: 375px;
	border-radius: 30px;
}

.blog-details .bottomIcons {
	display: flex;
}

.blog-details .bottomIcons .admin,
.blog-details .bottomIcons .comment {
	display: flex;
	align-items: baseline;
	gap: 3px;
	padding: 7px;
}

.blog-details .bottomIcons .admin span,
.blog-details .bottomIcons .comment span {
	font-size: 16px;
}

.blog-details .bottomIcons .admin p,
.blog-details .bottomIcons .comment p {
	font-size: 14px;
	font-weight: 700;
	color: grey;
}

.blog-details .container .leads li {
	line-height: 30px;
}

.quote-container {
	background-color: aqua;
	text-align: center;
	border-radius: 30px;
	padding: 18% 12px;
}

.quote-icon {
	border-radius: 1%;
	color: #fff;
	background-color: black;
	width: max-content;
	font-size: 36px;
	margin: auto;
}

.improvements li {
	text-transform: capitalize;
	font-size: 14px;
	margin-top: 15px;
	font-weight: 500;
}

.blog-details .container .share {
	display: flex;
	justify-content: space-between;
}

.blog-details .container .share .share-icons {
	display: flex;
	gap: 15px;
}

.blog-details .container .share .heading h6 {
	font-weight: 700;
}

.blog-details .container .row .form-group label {
	font-size: 13px;
	color: var(--text);
	font-weight: 500;
}

.blog-details .container .row .form-group input {
	border: none;
	border-bottom: 1px solid grey;
	border-radius: 0;
}

.blog-details .container .row .form-check input {
	border: 1px solid grey;
	border-radius: 0.25em;
}

.blog-details .container .row .form-check label {
	font-size: 12px;
	font-weight: 400;
}

.blog-details .container .row .form-group input:focus {
	box-shadow: none;
}

.blog-details .container .row .form-group input::placeholder {
	font-size: 12px;
	color: lightgray;
}

.blog-details .search-main {
	background-color: #fff;
	padding: 25px;
	border-radius: 20px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.blog-details .search-main .search-child {
	display: flex;
	justify-content: space-between;
	background-color: lightgray;
	padding: 10px;
	border-radius: 10px;
}

.blog-details .search-main .search-child button .icon {
	font-size: 20px;
}

.blog-details .search-main .search-child .searchInput:focus {
	border: none;
	outline: none;
}


.blog-details .container .post1 img {
	height: 80px;
	width: 80px;
	border-radius: 10px;
}

.blog-details .container .posts {
	display: flex;
	align-items: center;
	gap: 12px;
	border-bottom: 1px solid lightgray;
}

.posts-section,
.categories {
	border-radius: 20px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.blog-details .categories .bottomText {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid lightgray;

}

.blog-details .categories .bottomText p {
	font-size: 14px;
	font-weight: 500;
}

.blogIImg {
	height: 85px;
	width: 85px;
	border-radius: 10px;
}

/* case Study */
.header-bg {
	/* background-image: url('./Images/Rectangle\ 1.png'); */
	height: 500px;
	position: relative;
	padding: 0;
	margin: 0;
}

.casemask1,
.casemask2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -3;
}

/* Add the rest of your styles */

.header-bg .header-heading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fff;
	height: 500px;
}

.header-bg .header-heading h4 {
	font-size: 24px;
	font-weight: 500;
	line-height: 29px;
	letter-spacing: 0em;
}

.header-bg .header-heading h1 {
	font-size: 64px;
	font-weight: 600;
	line-height: 78px;
	letter-spacing: 0em;

}

/* .wholesale{
	position: absolute;
	bottom: 30px;
	left:30px;
  } */

.wholesale-text h5 {
	font-size: 24px;
}

.wholesale-link a {
	text-decoration: none;
	color: #59B1E1;
	font-size: 18px;
	font-weight: 500;
}

.caseStudy-shopping .mainSection .shop-banners {
	position: absolute;
	top: 30px;
	width: 100%;
}

.shop-banners img,
.collection img {
	width: 100%;
}

.caseStudy-shopping .mainSection {
	position: relative;
}

.photography-section .mainSection {
	position: relative;
}

/* .photography-section .photography{
	position: absolute;
	bottom: 30px;
	left:30px;
  } */
.studio-link a {
	text-decoration: none;
	color: #59B1E1;
	font-size: 18px;
	font-weight: 500;
}

.studio-text {
	padding-top: 20px;
}

.studio-text h5 {
	font-size: 24px;
}

.collection {
	position: absolute;
	top: 15px;
}

/* case study  */
.case_details .caseDetail ul:nth-child(1) li h5 {
	/* font-size: 19px; */
	font-weight: 800;
}

.case_details .caseDetail ul:nth-child(2) li h5 {
	font-size: 19px;
	font-weight: 400;
}

.case_details .projectImgs img {
	width: 100%;
}


.mainFrame {
	overflow: hidden;
}

.animTmgContainer {
	height: 260px;
	width: 77.1%;
	overflow: hidden;
	position: relative;
	margin-top: 20px;
	margin-left: 2px;
}

.animateImge {
	height: 200% !important;
	width: 100% !important;
	animation: moveimageUp infinite 5s ease;
}

.animateImge2 {
	height: 200% !important;
	width: 100% !important;
	animation: moveimageUp2 infinite 5s ease;
}

.animateImge3 {
	height: 200% !important;
	width: 100% !important;
	animation: moveimageUp3 infinite 5s ease;
}

.tabImgeContainer {
	height: 193px;
	width: 94%;
	overflow: hidden;
	position: relative;
	margin-top: 18px;
	margin-left: 2px;
}

.taabLayer {
	border-radius: 10px;
	z-index: 1;
	overflow: hidden;
	position: relative;
	height: 220px;
	width: 278px !important;
	left: -80px !important;
}

.contactFrame iframe {
	height: 200px !important;
	width: 100% !important;
}

@keyframes moveimageUp {
	0% {
		margin-top: 0px;
	}

	50% {
		margin-top: -188px;
	}

	100% {
		margin-top: 0px;
	}
}

@keyframes moveimageUp2 {
	0% {
		margin-top: 0px;
	}

	50% {
		margin-top: -124px;
	}

	100% {
		margin-top: 0px;
	}
}

@keyframes moveimageUp3 {
	0% {
		margin-top: 0px;
	}

	50% {
		margin-top: -125px;
	}

	100% {
		margin-top: 0px;
	}
}

#slider-container {
	position: relative;
	overflow: hidden;
	padding: 20px;
	margin: auto;
}

#slider-container .topbars {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#slider-container .btn {
	border: none;
	background-color: #eef7ff;
	padding: 10px;
	border-radius: 84%;
	color: gray;
}

#slider-container .btn.inactive {
	border-color: rgb(153, 121, 126)
}

#slider-container #slider {
	display: flex;
	width: 1000%;
	transition: all .5s;
}

#slider-container #slider .slide {
	margin: auto 10px;
	background-color: #eef7ff;
}

#slider-container #slider .slide span {
	color: white;
	font-size: 150px;
}

@media only screen and (min-width: 1100px) {

	#slider-container #slider .slide {
		width: calc(2.5% - 20px);
	}

}

@media only screen and (max-width: 1100px) {

	#slider-container #slider .slide {
		width: calc(3.3333333% - 20px);
	}


}

@media only screen and (max-width: 900px) {

	#slider-container #slider .slide {
		width: calc(5% - 20px);
	}

}

@media only screen and (max-width: 550px) {

	#slider-container #slider .slide {
		width: calc(10% - 20px);
	}

}

.sliderCard {
	padding: 20px;
}

.cardimgrow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.userDetail {
	display: flex;
	align-items: center;
}

/* Responsive */
@media screen and (max-width:1199px) {
	.lastCase {
		margin-left: 0px !important;
	}

	.caseImgContaier {
		position: relative;
		width: 77% !important;
		height: 100% !important;
		margin-left: 1px;
		margin-top: -7px;
	}

	.desxtopTesti {
		width: 450px !important;
	}

	.taabLayer {
		width: 200px !important;
	}

	.animTmgContainer {
		height: 214px;
		margin-top: 15px;
	}

	.tabImgeContainer {
		height: 139px;
		margin-top: 13px;
	}
}

@media screen and (max-width:991px) {
	.areyou .container .row .col-lg-7 .image1 img {
		left: 0%;
		top: 0%;
		position: relative;
	}

	.areyou .container .row .col-lg-7 .image2 img {
		left: 0%;
		top: 0%;
		position: relative;
	}

	.areyou .container .row .col-lg-7 .image3 img {
		left: 0%;
		top: 0%;
		position: relative;
	}

	.header {
		display: none;
	}

	.header2 {
		display: block;
	}

	.testi-img .img-box {
		max-width: 300px;
	}

	.testi-img .circle {
		height: 300px;
		width: 300px;
	}

	.testi-img .img-box-inner {
		border-radius: 0 0 150px 150px;
	}

	.testi-img .img-box-inner img {
		max-width: 230px;
	}

	.animTmgContainer {
		height: 215px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 15px;
		margin-left: 2px;
	}

	.tabImgeContainer {
		height: 139px;
		width: 94%;
		overflow: hidden;
		position: relative;
		margin-top: 12px;
		margin-left: 2px;
	}

	.caseImgContaier {
		height: 163px !important;
	}

	.caseStudy-shopping .mainSection .shop-banners,
	.collection {
		position: relative;
		top: 0;
		left: 0;
		margin: auto;
	}

	.imagesContainer {
		height: 100% !important;
		width: 100% !important;
	}

	.case_study .casecainter {
		height: 163px !important;
	}
}

@media screen and (max-width:820px) {
	@keyframes moveUpAnim {
		0% {
			margin-top: 0px;
		}

		25% {
			margin-top: -34px;
		}

		50% {
			margin-top: -82px;
		}

		75% {
			margin-top: -34px;
		}

		100% {
			margin-top: -34px;
		}
	}

	.taabLayer {
		display: none !important;
	}

	.maintesti {
		left: 0 !important;
	}
}

@media screen and (max-width:768px) {
	.areyou .container .row .col-lg-7 .row .col-md-4 {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}

	.degital_cards .container .row .cont .card {
		position: relative;
		top: 0%;
	}

	.development_cards .container .row .cont .card {
		position: relative;
		top: 0%;
	}

	.testi-img {
		margin-bottom: 25px;
	}

	/* banner Imag */
	.banner .bannerContent .contentContainer .bannerImg {
		justify-content: center;
		align-items: end;
	}

	.banner .bannerContent .contentContainer .bannerImg img {
		height: 80% !important;
	}

	.contentContainer {
		height: 100% !important;
	}

	.digital_marketing_cards .downcrds {
		position: relative;
		top: 0px;
	}

	.digital_marketing_cards .downcrds2 {
		position: relative;
		top: 0px;
	}

	.cards2 {
		position: relative;
		top: 0 !important;
	}

	.case_study .container .row .case_content h1.h {
		/* font-size: 2.6rem; */
		font-weight: 800;
		color: #ffa500;
		text-shadow: 0px 2px 5px #ffa60088;
	}

	.growth .row .col-lg-12 h1 {
		font-size: 22px;
		font-weight: 600;
	}

	.growth .row .col-lg-12 p {
		font-size: 15px;
		color: var(--text);
	}

	.caseContainer {
		width: 76% !important;
		height: 227px;
		margin: auto;
	}

	.caseImgContaier {
		height: 83% !important;
	}

	.case_study .container .row .col-md-6 .scrollAnimation {
		height: 100%;
		width: 100%;
		display: flex;
		overflow: visible !important;
		align-items: center;
		margin: 30px 0px !important;
	}

	.animTmgContainer {
		height: 172px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

	.tabImgeContainer {
		height: 112px;
		width: 94%;
		overflow: hidden;
		position: relative;
		margin-top: 8px;
		margin-left: 2px;
	}

	.areyou .container .row h1 .animateDive {
		animation: none;
	}

	.head2 {
		display: none;
	}

	.head3 {
		display: none;
	}

	.blog .container .row .col-md-3 .blog-img img {
		width: 100% !important;
		height: 220px !important;
	}

	.blogIImg {
		height: 100%;
		width: 100%;
	}

	.animTmgContainer {
		height: 213px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 16px;
		margin-left: 2px;
	}

	.case_study .casecainter {
		height: 83% !important;
	}

	.contact_container_form {
		padding-right: 0;
	}

	.about_us_value .container .value-cards .heading {
		padding: 1rem;
	}

	.growth {
		height: 100%;
		margin-bottom: 1rem;
	}
}

@media screen and (max-width:512px) {
	.banner .bannerContent .contentContainer .bannerText h1 {
		line-height: 44px;
		font-size: 30px;
	}

	.banner .bannerContent .contentContainer .bannerText p {
		line-height: normal;
		font-size: 15px;
	}

	.innovate_container .row .inovate_content h1 {
		font-size: 3.4rem;
	}

}

@media screen and (max-width:660px) {
	.caseContainer {
		width: 300px !important;
		height: 227px !important;
		left: 1pc !important;
	}

	.caseImgContaier {
		height: 64% !important;
	}

	.limitation .container-fluid .row .textSection .imge {
		display: none !important;
	}

	.limitation .container-fluid .row .textSection h1:nth-child(1) {
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
	}

	.limitation .container-fluid .row .textSection h1:nth-child(2) {
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
	}

	.limitation .container-fluid .row .textSection h1:nth-child(3) {
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
	}

	.limitation .container-fluid .row .textSection .ro {
		/* position: relative; */
		left: 0 !important;
		width: 100% !important;
	}

	.limitation .container-fluid .bottomRow {
		margin-top: 50px;
	}

	.headingSection {
		height: 100%;
		margin-bottom: 80px;
	}

	.digital_marketing_cards .container .row .col-md-6 .text h1 {
		font-size: 30px;
	}

	.maintesti {
		left: -7rem !important;
	}

	.case_study .casecainter {
		height: 64% !important;
	}
}

@media screen and (max-width:575px) {
	.maintesti {
		left: -9rem !important;
	}

	.desxtopTesti {
		width: 400px !important;
	}
}

@media screen and (max-width:615px) {
	.animTmgContainer {
		height: 215px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 15px;
		margin-left: 2px;
	}

}

@media screen and (max-width:435px) {
	.faq_container .row:nth-child(1) .frequently {
		font-size: 21px;
	}

	.case_details .caseDetail ul:nth-child(1) li h5 {
		font-size: 15px;
		font-weight: 800;
	}

	.case_details .caseDetail ul:nth-child(2) li h5 {
		font-size: 15px;
		font-weight: 400;
	}

	.animTmgContainer {
		height: 150px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}
}

@media screen and (max-width:475px) {
	.growth .row .col-lg-12 h1 {
		font-size: 18px;
		font-weight: 600;
	}

	.maintesti {
		left: -12rem !important;
	}

	.value-cards .heading {
		width: 100% !important;
	}

	.about_us_value .container .value-cards .img_container {
		width: 100% !important;
	}
}

@media screen and (max-width: 1246px) {
	.limitation .container-fluid .row .textSection h1:nth-child(1) {
		font-size: 57px;
		position: absolute;
		bottom: 5rem;
		left: 16rem;
		color: var(--primary-color);
	}

	.limitation .container-fluid .row .textSection h1:nth-child(3) {
		font-size: 57px;
	}
}

@media screen and (max-width: 1227px) {
	.limitation .container-fluid .row .textSect .content p {
		font-weight: 300;
		position: absolute;
		top: 38px;
		line-height: 25px;
	}
}

@media screen and (max-width: 1211px) {
	.limitation .container-fluid .row .textSection h1:nth-child(3) {
		font-size: 39px;
		position: absolute;
		bottom: 6.3rem;
		left: 16rem;
		color: var(--primary-color);
	}

	.limitation .container-fluid .row .textSection h1:nth-child(1) {
		font-size: 39px;
		position: absolute;
		top: 6rem;
		left: 16rem;
		color: var(--primary-color);
	}
}

@media screen and (max-width:1199px) {
	.casecainter {
		width: 78% !important;
		height: 100% !important;
	}
}

@media screen and (max-width: 1168px) {
	.limitation .container-fluid .row .textSection .imge {
		display: flex;
		align-items: center;
		position: relative;
		left: -29px;
	}

	.limitation .container-fluid .row .textSection h1:nth-child(2) {
		left: 17rem;
	}

	.limitation .container-fluid .row .textSection .ro {
		left: 9rem;
	}

	.limitation .container-fluid .row .textSect {
		position: relative;
		overflow-y: hidden;
	}
}

@media screen and (max-width:615px) {
	.animTmgContainer {
		height: 167px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

	.animTmgContainer {
		height: 167px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}
}

@media screen and (max-width:605px) {
	.animTmgContainer {
		height: 163px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

	.tabImgeContainer {
		height: 104px;
		width: 94%;
		overflow: hidden;
		position: relative;
		margin-top: 8px;
		margin-left: 1px;
	}
}

@media screen and (max-width:576px) {
	.animTmgContainer {
		height: 194px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

	.tabImgeContainer {
		height: 99px;
		width: 94%;
		overflow: hidden;
		position: relative;
		margin-top: 8px;
		margin-left: 0px;
	}

	.areyou .container .row h1 {
		height: 100%;
	}

	.taabLayer {
		height: 0 !important;
	}

	.maintesti {
		display: block !important;
	}

	.development_quote {
		position: relative;
		padding: 0rem;
	}

}

@media screen and (max-width:465px) {
	.desxtopTesti {
		left: -40px;
	}
}

@media screen and (max-width:425px) {
	.desxtopTesti {
		left: -10px;
		width: 300px !important;
	}

	.animTmgContainer {
		height: 143px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

}

@media screen and (max-width:412px) {
	.animTmgContainer {
		height: 97px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 6px;
		margin-left: 2px;
	}
}

@media screen and (max-width:414px) {
	.animTmgContainer {
		height: 148px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 6px;
		margin-left: 2px;
	}

	.maintesti {
		display: block !important;
	}

	.desxtopTesti {
		left: 2rem;
	}

	@keyframes moveimageUp {
		50% {
			margin-top: -150px;
		}
	}

	.backtotop {
		position: absolute;
		top: -30px;
		left: 45%;
	}

	.development_tool .container .row .col-md-8 .tools .tool {
		text-align: center;
		width: 26%;
	}

	.banner {
		padding-top: 5rem !important;
	}

	.Digital_marketing_feature {
		padding: 1rem !important;
	}

	.development_quote2 h1 {
		font-size: 30px;
	}

	.development_quote,
	.development_quote2 {
		height: 480px;
	}

	.mainCaseImgContainer img {
		height: 450px !important;
	}

	.imagesContainer {
		height: 350px !important;
		width: 100% !important;
	}

	.collection {
		margin-top: 10px;
	}

	.caseStudy-header .container-fluid {
		padding: 0px 12px !important;
	}

	.mainSection {
		padding: 0px 12px !important;
		margin-top: 10px !important;
	}

	.header-bg .header-heading {
		height: 300px !important;
	}

	.header-bg {
		height: 300px !important;
		margin-top: 3.8rem;
	}

	.header-bg .header-heading h1 {
		font-size: 40px;
		font-weight: 600;
		line-height: 78px;
		letter-spacing: 0em;
	}

	.header-bg .header-heading h4 {
		font-size: 20px;
		font-weight: 500;
		line-height: 29px;
		letter-spacing: 0em;
	}
}

@media screen and (max-width:375px) {
	.animTmgContainer {
		height: 143px;
		width: 77.1%;
		overflow: hidden;
		position: relative;
		margin-top: 10px;
		margin-left: 2px;
	}

	.caseImgContaier {
		height: 54% !important;
	}

	.caseContainer {
		position: relative;
		left: -2% !important;
	}

	.backtotop {
		position: absolute;
		top: -30px;
		left: 43%;
	}

	.maintesti {
		margin-left: -1rem !important;
	}

	@keyframes moveimageUp {
		50% {
			margin-top: -150px;
		}
	}

	.case_study .casecainter {
		height: 54% !important;
	}

	/* .header-bg {
		height: ;
	} */
}

.serviceDropdownContent {
	/* text-align: center !important; */
	padding: 10px;
}

.dropdownMenu {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.dropdownMenu li {
	position: relative;
	padding: 5px;
}

.dropdownMenu li::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0%;
	height: 1px;
	background-color: #ffb20b;
	transition: width 0.3s ease;
	/* Animation duration and timing */
}

.dropdownMenu li:hover::after {
	width: 100%;
	/* Expand width to 100% on hover */
}
.people__slide .swiper-slide {
	width: 297px;
	padding: 30px 0;
	transform: scale(0.75);
	transition: 0.3s;
}
.people__slide .swiper-slide .people__card {
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 276px;
	padding: 25px 30px 30px;
	border-radius: 48px;
	/* background-color: linear-gradient(135deg, #000, #000, #000, #000, #000, #000, #000, #000); */
	background-color: #191139;
	transition: 0.3s;
}
.people__slide .swiper-slide .people__card .people__image {
	position: relative;
	padding: 4px;
	width: 150px;
	height: 150px;
	margin-bottom: 15px;
	overflow: hidden;
	border-radius: 100%;
	border: 5px solid rgba(255, 255, 255, 0.5);
}
.people__slide .swiper-slide .people__card .people__image img {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
}
.people__slide .swiper-slide .people__card .people__info {
	display: flex;
	flex-flow: column;
	align-items: center;
}
.people__slide .swiper-slide .people__card .people__social {
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	padding-left: 0;
}
.people__slide .swiper-slide .people__card .people__social li {
	padding: 0 5px;
}
.people__slide .swiper-slide .people__card .people__social li a {
	padding: 5px;
	color: #fff;
}
.people__slide .swiper-slide .people__card .people__name {
	margin-bottom: 10px;
}
.people__slide .swiper-slide .people__card .people__position {
	margin-bottom: 15px;
	font-size: 14px;
	color: #fff;
}
.people__slide .swiper-slide .people__card .people__desc {
	margin-bottom: 20px;
	font-size: 13px;
	line-height: 1.4;
	color: #fff;
	text-align: center;
}
.people__slide .swiper-slide .people__card .people__btn {
	display: inline-flex;
}
.people__slide .swiper-slide .people__card .people__btn a {
	color: #fff;
	border-radius: 10px;
	padding: 10px 20px;
	border: solid 1px #fff;
	transition: 0.25s;
}
.people__slide .swiper-slide .people__card .people__btn a:hover {
	background-color: #fff;
	color: #e26565;
}
.people__slide .swiper-slide.swiper-slide-active {
	animation-name: elastic1;
	animation-duration: 0.6s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}
.people__slide .swiper-slide.swiper-slide-active {
	animation-name: elastic1_sp;
}
.people__slide .swiper-slide:nth-child(even) .people__card {
	transform: rotate(-5deg);
}
.people__slide .swiper-slide:nth-child(odd) .people__card {
	transform: rotate(5deg);
}
@keyframes elastic1 {
	0% {
		transform: scale(1);
   }
	40% {
		transform: scale(1.4);
   }
	55% {
		transform: scale(1.27);
   }
	70% {
		transform: scale(1.36);
   }
	85% {
		transform: scale(1.31);
   }
	100% {
		transform: scale(1.33);
   }
}
@keyframes elastic1_sp {
	0% {
		transform: scale(0.75);
   }
	40% {
		transform: scale(1.05);
   }
	55% {
		transform: scale(0.95);
   }
	70% {
		transform: scale(1.02);
   }
	85% {
		transform: scale(0.98);
   }
	100% {
		transform: scale(1);
   }
}
